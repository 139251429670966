import React from "react";
import "@src/scss/App.scss";
import Seo from "@components/seo";
import Layout from "@components/layout";
import { graphql } from 'gatsby'
import ModuleSection from '@components/modules/moduleWrapper';
import BannerRender from "@components/modules/Banner/BannerRender";
import Col from "react-bootstrap/Col";
import { Stack } from "react-bootstrap";
import ApplyGuest from '@components/modules/Forms/ApplyGuest';
import { StaticImage } from "gatsby-plugin-image";
import { Routes } from '@components/routes';

import { delayItemsFromBottom } from '@components/utils/animations';

function HtmlText(props) {
    return <span dangerouslySetInnerHTML={createHTMLMarkup(props)} className={props?.className} />;
}
function createHTMLMarkup(props) {
    return {__html: (props.html)};
}

const PodcastsLandingPages = ({
    // pageContext,
    data,
    // location
}) => {
    const { glstrapi: { page, podcasts } } = data
    const breadcrumbs = [
        { label: "Media", url: `${Routes.MediaPage}` },
        { label: page?.Pagename, url: `${Routes.MediaPage}/${page?.Slug}` }
    ]

    return (
        <Layout popularSearch={page?.Popular_Search} bodyClass="podcasts-landing">
            <Seo
                title={page?.Metadata?.title ? page.Metadata.title : page.Title}
                description={page?.Metadata?.description ? page.Metadata.description : null}
                // image={page?.Metadata?.image?.url ? page.Metadata.image.url : null}
                image={page?.Metadata?.image?.url_sharp?.childImageSharp ? page.Metadata.image.url_sharp.childImageSharp.resize : null}
            />
            <BannerRender
                page={page}
                breadcrumbs={breadcrumbs}
            />
            <ModuleSection sectionSpacing="py-40 py-md-64 py-lg-80">
                <Stack gap={32} direction="horizontal" className="podcasts-sources mb-40">
                    <a href="https://propertytalkfromlouiseslounge.buzzsprout.com/" className="d-block" target="_blank" rel="noreferrer">
                        <Stack direction="vertical" className="gap-8 gap-md-24 flex-md-row">
                            <div>
                                <StaticImage
                                    src="../images/buzzsprout.png"
                                    width={152}
                                    height={24}
                                    quality={100}
                                    formats={["auto", "webp"]}
                                    alt="Subscribe to Buzzsprout"
                                />
                            </div>
                            <p>Subscribe to Buzzsprout</p>
                        </Stack>
                    </a>
                    <div className="vr" />
                    <a href="https://www.youtube.com/c/Exclusive-links" className="d-block" target="_blank" rel="noreferrer">
                        <Stack direction="vertical" className="gap-8 gap-md-24 flex-md-row">
                            <div>
                                <StaticImage
                                    src="../images/youtube.png"
                                    width={92}
                                    quality={19}
                                    formats={["auto", "webp"]}
                                    alt="Subscribe to YouTube"
                                />
                            </div>
                            <p>Subscribe to YouTube</p>
                        </Stack>
                    </a>
                </Stack>
                <div className="row row-cols-1">
                    {podcasts?.map((podcast, i) => {
                        return(
                            <React.Fragment key={i}>
                                {podcast?.Buzzsprout &&
                                    <Col variants={delayItemsFromBottom} custom={i}>
                                        <HtmlText html={podcast.Buzzsprout} />
                                    </Col>
                                }
                            </React.Fragment>
                        )
                    })}
                </div>
                <Stack gap={32} direction="horizontal" className="podcasts-sources mt-40">
                    <a href="https://propertytalkfromlouiseslounge.buzzsprout.com/" className="d-block" target="_blank" rel="noreferrer">
                        <Stack direction="vertical" className="gap-8 gap-md-24 flex-md-row">
                            <div>
                                <StaticImage
                                    src="../images/buzzsprout.png"
                                    width={152}
                                    height={24}
                                    quality={100}
                                    formats={["auto", "webp"]}
                                    alt="Subscribe to Buzzsprout"
                                />
                            </div>
                            <p>Subscribe to Buzzsprout</p>
                        </Stack>
                    </a>
                    <div className="vr" />
                    <a href="https://www.youtube.com/c/Exclusive-links" className="d-block" target="_blank" rel="noreferrer">
                        <Stack direction="vertical" className="gap-8 gap-md-24 flex-md-row">
                            <div>
                                <StaticImage
                                    src="../images/youtube.png"
                                    width={92}
                                    quality={19}
                                    formats={["auto", "webp"]}
                                    alt="Subscribe to YouTube"
                                />
                            </div>
                            <p>Subscribe to YouTube</p>
                        </Stack>
                    </a>
                </Stack>
            </ModuleSection>
            <ApplyGuest
                sectionClass="section-grey section-contact-form"
                title="Apply to be a Guest or Suggest a Topic"
                // text="<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla et tristique nunc, ac rhoncus tellus. Morbi orci orci, tempor eget vestibulum in, elementum at ex. Phasellus nec ligula vitae metus cursus laoreet.</p>"
            />
        </Layout>
    );
}

export default PodcastsLandingPages

export const query = graphql`
query podcastLandingQuery($id: ID!) {
    glstrapi {
        page(id: $id, publicationState: LIVE) {
            Pagename
            Title
            Slug
            HeaderTemplate
            Popular_Search
            BannerImage {
                ... on GLSTRAPI_ComponentModulesBannerImage {
                    ...ImageBannerFragment
                }
            }
            Metadata {
                title
                description
                image {
                    url
                    url_sharp {
                        childImageSharp {
                            resize(width: 1200) {
                                src
                                height
                                width
                            }
                        }
                    }
                }
            }
        }
        podcasts(publicationState: LIVE, sort: "createdAt:DESC") {
            Title
            Slug
            id
            Buzzsprout
            Episode
            BannerImage {
                ... on GLSTRAPI_ComponentModulesBannerImage {
                    Image {
                        url
                        url_sharp {
                            childImageSharp {
                                gatsbyImageData(width: 360, quality: 100)
                            }
                        }
                    }
                }
            }
        }
    }
}
`