import * as React from "react";
import { graphql } from "gatsby";
import Stack from "react-bootstrap/Stack";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import ModuleSection from '@components/modules/moduleWrapper';
import ModuleTitle from '@components/modules/Title';
import ModuleText from '@components/modules/Text';
import { StarberryIcons } from "@components/icons/index";
// import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { onlyNumbers, uaenumberformat } from '@components/utils/CleanedHtml';
import ButtonLink from '@components/ButtonLink';
import Routes from '@components/routes';
import { ModalConsumer } from '@components/modal/ModalContext';
import { ContactModal } from '@components/modal/modals';
import { ContactMemberModal } from '@components/modal/modals';
import { fadeIn } from '@components/utils/animations';

import './teamContact.scss';

const TeamContact = ({ moduleData, team, department, contact_label_list }) => {
    const teamPhone = "+ 971 4 399 4937";
    const teamWhatsApp = "+ 971 5 589 76951";
    const teamEmail = "enquiry@exclusive-links.com";

    
    return(
        <ModuleSection removeContainer={moduleData?.removeContainer ? moduleData.removeContainer : false} sectionSpacing={moduleData?.sectionSpacing} sectionClass={`module team-contact-module ${moduleData?.moduleClass ? moduleData?.moduleClass : ''}`}>
            {moduleData?.Title &&
                <Row className="mb-lg-96">
                    {moduleData?.Title &&
                        <Col lg={5}>
                            <div variants={fadeIn}>
                                <ModuleTitle
                                    title={moduleData.Title}
                                    className="search-icon-box__title mb-16 mb-md-24 mb-lg-0"
                                />
                            </div>
                        </Col>
                    }
                    {moduleData?.Text &&
                        <Col lg={{ span: 6, offset: 1 }}>
                            <div variants={fadeIn}>
                                <ModuleText
                                    text={moduleData.Text}
                                    className="search-icon-box__text d-block mb-35 mb-md-48 mb-lg-0"
                                />
                            </div>
                        </Col>
                    }
                </Row>
            }

            {!team &&
                <Stack className="gap-24">
                    <Stack direction="horizontal" className="gap-16 align-items-start flex-lg-column">
                    {department === "international_projects" ?
                    <img src={"https://ggfx-exclusivelinks.s3.eu-west-2.amazonaws.com/i.prod/Zarah_Evans_112ae00bbe.jpg"} alt={"Zarah Evans - Owner / Managing Partner - ExclusiveLinks"} className="team-contact-avatar" />
                    :
                        <StarberryIcons iconName="logoContactIcon" />
                        }
                        <Stack>
                            <Stack className="gap-4 team-contact-name">
                                <span className="team-contact-title">{department === "international_projects" ? "Zarah Evans" : contact_label_list?.contact_us ? contact_label_list?.contact_us : "Contact Us"}</span>
                                <span className="team-contact-text">{department === "international_projects" ? "Owner / Managing Partner" : contact_label_list?.Were_here_to_help ? contact_label_list?.Were_here_to_help : "We’re here to help."}</span>
                            </Stack>
                            <Stack className="gap-8 gap-md-40 gap-lg-8 mt-16 mt-md-24 team-contact-btns flex-md-row flex-lg-column align-items-start">
                                {department === "international_projects" ? "" : <ButtonLink manualLink={`https://wa.me/${onlyNumbers(teamWhatsApp)}`} label={uaenumberformat(teamWhatsApp)} icon="whatsAppIcon" variant="link-gray" className="whatsApp" />}
                                <ButtonLink manualLink={`tel:${uaenumberformat(teamPhone)}`} label={uaenumberformat(teamPhone)} icon="phoneIcon" variant="link-gray" />
                                <ModalConsumer>
                                    {({ showModal }) => (
                                        <Button variant="link-gray" className="email_us" onClick={() => department === "international_projects" ? showModal(ContactMemberModal, { show: true,  email: "international@exclusive-links.com"}) : showModal(ContactModal, { show: true })}><StarberryIcons iconName="emailIcon" className="bi" /><span>{ contact_label_list?.Email_us ? contact_label_list?.Email_us : "Email Us"}</span></Button>
                                    )}
                                </ModalConsumer>
                                {department === "international_projects" ? <ButtonLink btnClass="mt-8 mt-md-0 mt-lg-16 team-contact-view-more" manualLink={`${Routes.AboutPage}${Routes.TeamsPage}/zarah-evans`} variant="link-primary-underlined">View My Profile</ButtonLink> :
                                <ButtonLink btnClass="mt-8 mt-md-0 mt-lg-16 team-contact-view-more" manualLink={`${Routes.ContactUsPage}`} variant="link-primary-underlined">{ contact_label_list?.view_our_office ? contact_label_list?.view_our_office :  "View Our Offices" }</ButtonLink>}
                            </Stack>
                        </Stack>
                    </Stack>
                </Stack>
            }
            {team?.id &&
                <Stack className="gap-24">
                    <Stack direction="horizontal" className="gap-16 align-items-start flex-lg-column">
                        {/* <GatsbyImage
                            image={getImage(team?.Image?.url_sharp)}
                            alt={team?.Image?.alternativeText ? team.Image.alternativeText : team?.Name}
                            className="team-contact-avatar"
                        /> */}
                        <img src={team?.Image?.url} alt={team?.Image?.alternativeText?team?.Image?.alternativeText + " - Exclusive Links":team?.Name+ " - Exclusive Links"} className="team-contact-avatar" />
                        <Stack>
                            <Stack className="gap-4 team-contact-name">
                                <span className="team-contact-title">{team?.Name}</span>
                                <span className="team-contact-text">{team?.JobTitle}</span>
                            </Stack>
                            <Stack className="gap-8 gap-md-40 gap-lg-8 mt-16 mt-md-24 team-contact-btns flex-md-row flex-lg-column align-items-start">
                                {team?.WhatsApp &&
                                    <ButtonLink manualLink={`https://wa.me/${onlyNumbers(team.WhatsApp)}`} label={uaenumberformat(team.WhatsApp)} icon="whatsAppIcon" variant="link-gray" className="whatsApp" />
                                }
                                {team?.Phone &&
                                    <ButtonLink manualLink={`tel:${uaenumberformat(team.Phone)}`} label={uaenumberformat(team.Phone)} icon="phoneIcon" variant="link-gray" />
                                }
                                {team?.Email &&
                                    <ModalConsumer>
                                        {({ showModal }) => (
                                            <Button variant="link-gray" onClick={() => showModal(ContactModal, { show: true, title: `Email ${team?.Name?.split(' ')[0]}` })}><StarberryIcons iconName="emailIcon" className="bi" />Email Us</Button>
                                        )}
                                    </ModalConsumer>
                                }
                                <ButtonLink btnClass="mt-8 mt-md-0 mt-lg-16 team-contact-view-more" manualLink={`${Routes.AboutPage}${Routes.TeamsPage}/${team?.Slug}`} variant="link-primary-underlined">View My Profile</ButtonLink>
                            </Stack>
                        </Stack>
                    </Stack>
                </Stack>
            }
        </ModuleSection>
    )
}

export default TeamContact

export const teamContactFragment = graphql`
    fragment TeamContactFragment on GLSTRAPI_ComponentModulesTeamContact {
        id
        moduleType
        moduleData {
            Text
            Title
            moduleClass
            sectionSpacing
        }
        team {
            id
            JobTitle
            Name
            Phone
            Slug
            WhatsApp
            Image {
                alternativeText
                url
                url_sharp {
                    childImageSharp {
                        gatsbyImageData(width: 80, height: 80, quality: 100)
                    }
                }
            }
            Email
        }
    }
`